/**
 * Partnership Management
 * This is Swagger UI environment generated for the TMF Partnership Management specification
 *
 * OpenAPI spec version: 4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { PartnershipSpecification } from '../model/partnershipSpecification';
import { PartnershipSpecificationCreate } from '../model/partnershipSpecificationCreate';
import { PartnershipSpecificationUpdate } from '../model/partnershipSpecificationUpdate';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class PartnershipSpecificationService {

  protected basePath = 'https://serverRoot/tmf-api/partnershipManagement/v4/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Creates a PartnershipSpecification
   * This operation creates a PartnershipSpecification entity.
   * @param body The PartnershipSpecification to be created
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPartnershipSpecification(body: PartnershipSpecificationCreate, observe?: 'body', reportProgress?: boolean): Observable<PartnershipSpecification>;
  public createPartnershipSpecification(body: PartnershipSpecificationCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PartnershipSpecification>>;
  public createPartnershipSpecification(body: PartnershipSpecificationCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PartnershipSpecification>>;
  public createPartnershipSpecification(body: PartnershipSpecificationCreate, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createPartnershipSpecification.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<PartnershipSpecification>('post', `${this.basePath}/partnershipSpecification`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes a PartnershipSpecification
   * This operation deletes a PartnershipSpecification entity.
   * @param id Identifier of the PartnershipSpecification
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePartnershipSpecification(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deletePartnershipSpecification(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deletePartnershipSpecification(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deletePartnershipSpecification(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deletePartnershipSpecification.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/partnershipSpecification/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * List or find PartnershipSpecification objects
   * This operation list or find PartnershipSpecification entities
   * @param fields Comma-separated properties to be provided in response
   * @param offset Requested index for start of resources to be provided in response
   * @param limit Requested number of resources to be provided in response
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listPartnershipSpecification(fields?: string, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PartnershipSpecification>>;
  public listPartnershipSpecification(fields?: string, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PartnershipSpecification>>>;
  public listPartnershipSpecification(fields?: string, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PartnershipSpecification>>>;
  public listPartnershipSpecification(fields?: string, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<PartnershipSpecification>>('get', `${this.basePath}/partnershipSpecification`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Updates partially a PartnershipSpecification
   * This operation updates partially a PartnershipSpecification entity.
   * @param body The PartnershipSpecification to be updated
   * @param id Identifier of the PartnershipSpecification
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchPartnershipSpecification(body: PartnershipSpecificationUpdate, id: string, observe?: 'body', reportProgress?: boolean): Observable<PartnershipSpecification>;
  public patchPartnershipSpecification(body: PartnershipSpecificationUpdate, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PartnershipSpecification>>;
  public patchPartnershipSpecification(body: PartnershipSpecificationUpdate, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PartnershipSpecification>>;
  public patchPartnershipSpecification(body: PartnershipSpecificationUpdate, id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchPartnershipSpecification.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchPartnershipSpecification.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<PartnershipSpecification>('patch', `${this.basePath}/partnershipSpecification/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves a PartnershipSpecification by ID
   * This operation retrieves a PartnershipSpecification entity. ClearingDataAttribute selection is enabled for all first level attributes.
   * @param id Identifier of the PartnershipSpecification
   * @param fields Comma-separated properties to provide in response
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retrievePartnershipSpecification(id: string, fields?: string, observe?: 'body', reportProgress?: boolean): Observable<PartnershipSpecification>;
  public retrievePartnershipSpecification(id: string, fields?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PartnershipSpecification>>;
  public retrievePartnershipSpecification(id: string, fields?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PartnershipSpecification>>;
  public retrievePartnershipSpecification(id: string, fields?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling retrievePartnershipSpecification.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PartnershipSpecification>('get', `${this.basePath}/partnershipSpecification/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
