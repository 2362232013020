/**
 * Partnership Management
 * This is Swagger UI environment generated for the TMF Partnership Management specification
 *
 * OpenAPI spec version: 4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { EventSubscription } from '../model/eventSubscription';
import { PartnershipChangeEvent } from '../model/partnershipChangeEvent';
import { PartnershipCreateEvent } from '../model/partnershipCreateEvent';
import { PartnershipDeleteEvent } from '../model/partnershipDeleteEvent';
import { PartnershipSpecificationChangeEvent } from '../model/partnershipSpecificationChangeEvent';
import { PartnershipSpecificationCreateEvent } from '../model/partnershipSpecificationCreateEvent';
import { PartnershipSpecificationDeleteEvent } from '../model/partnershipSpecificationDeleteEvent';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class NotificationListenersClientSideService {

  protected basePath = 'https://serverRoot/tmf-api/partnershipManagement/v4/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Client listener for entity PartnershipChangeEvent
   * Example of a client listener for receiving the notification PartnershipChangeEvent
   * @param body The event data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listenToPartnershipChangeEvent(body: PartnershipChangeEvent, observe?: 'body', reportProgress?: boolean): Observable<EventSubscription>;
  public listenToPartnershipChangeEvent(body: PartnershipChangeEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSubscription>>;
  public listenToPartnershipChangeEvent(body: PartnershipChangeEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSubscription>>;
  public listenToPartnershipChangeEvent(body: PartnershipChangeEvent, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling listenToPartnershipChangeEvent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EventSubscription>('post', `${this.basePath}/listener/partnershipChangeEvent`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Client listener for entity PartnershipCreateEvent
   * Example of a client listener for receiving the notification PartnershipCreateEvent
   * @param body The event data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listenToPartnershipCreateEvent(body: PartnershipCreateEvent, observe?: 'body', reportProgress?: boolean): Observable<EventSubscription>;
  public listenToPartnershipCreateEvent(body: PartnershipCreateEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSubscription>>;
  public listenToPartnershipCreateEvent(body: PartnershipCreateEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSubscription>>;
  public listenToPartnershipCreateEvent(body: PartnershipCreateEvent, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling listenToPartnershipCreateEvent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EventSubscription>('post', `${this.basePath}/listener/partnershipCreateEvent`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Client listener for entity PartnershipDeleteEvent
   * Example of a client listener for receiving the notification PartnershipDeleteEvent
   * @param body The event data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listenToPartnershipDeleteEvent(body: PartnershipDeleteEvent, observe?: 'body', reportProgress?: boolean): Observable<EventSubscription>;
  public listenToPartnershipDeleteEvent(body: PartnershipDeleteEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSubscription>>;
  public listenToPartnershipDeleteEvent(body: PartnershipDeleteEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSubscription>>;
  public listenToPartnershipDeleteEvent(body: PartnershipDeleteEvent, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling listenToPartnershipDeleteEvent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EventSubscription>('post', `${this.basePath}/listener/partnershipDeleteEvent`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Client listener for entity PartnershipSpecificationChangeEvent
   * Example of a client listener for receiving the notification PartnershipSpecificationChangeEvent
   * @param body The event data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listenToPartnershipSpecificationChangeEvent(body: PartnershipSpecificationChangeEvent, observe?: 'body', reportProgress?: boolean): Observable<EventSubscription>;
  public listenToPartnershipSpecificationChangeEvent(body: PartnershipSpecificationChangeEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSubscription>>;
  public listenToPartnershipSpecificationChangeEvent(body: PartnershipSpecificationChangeEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSubscription>>;
  public listenToPartnershipSpecificationChangeEvent(body: PartnershipSpecificationChangeEvent, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling listenToPartnershipSpecificationChangeEvent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EventSubscription>('post', `${this.basePath}/listener/partnershipSpecificationChangeEvent`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Client listener for entity PartnershipSpecificationCreateEvent
   * Example of a client listener for receiving the notification PartnershipSpecificationCreateEvent
   * @param body The event data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listenToPartnershipSpecificationCreateEvent(body: PartnershipSpecificationCreateEvent, observe?: 'body', reportProgress?: boolean): Observable<EventSubscription>;
  public listenToPartnershipSpecificationCreateEvent(body: PartnershipSpecificationCreateEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSubscription>>;
  public listenToPartnershipSpecificationCreateEvent(body: PartnershipSpecificationCreateEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSubscription>>;
  public listenToPartnershipSpecificationCreateEvent(body: PartnershipSpecificationCreateEvent, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling listenToPartnershipSpecificationCreateEvent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EventSubscription>('post', `${this.basePath}/listener/partnershipSpecificationCreateEvent`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Client listener for entity PartnershipSpecificationDeleteEvent
   * Example of a client listener for receiving the notification PartnershipSpecificationDeleteEvent
   * @param body The event data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listenToPartnershipSpecificationDeleteEvent(body: PartnershipSpecificationDeleteEvent, observe?: 'body', reportProgress?: boolean): Observable<EventSubscription>;
  public listenToPartnershipSpecificationDeleteEvent(body: PartnershipSpecificationDeleteEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSubscription>>;
  public listenToPartnershipSpecificationDeleteEvent(body: PartnershipSpecificationDeleteEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSubscription>>;
  public listenToPartnershipSpecificationDeleteEvent(body: PartnershipSpecificationDeleteEvent, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling listenToPartnershipSpecificationDeleteEvent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EventSubscription>('post', `${this.basePath}/listener/partnershipSpecificationDeleteEvent`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
