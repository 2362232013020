import { Route } from '@angular/router';
import { authGuard } from '@adlatus-gui/domain/authentication';

export const appRoutes: Route[] = [
  {
    path: '',
    title: 'Dashboard',
    loadComponent: () => import('./pages/main-page')
      .then(m => m.MainPageComponent)
  },
  {
    path: 'tickets/:type',
    title: 'My Issues',
    loadComponent: () => import('./pages/tickets-page')
      .then(m => m.TicketsPageComponent)
  },
  {
    path: 'tickets/:type/:id',
    title: 'Single Issue',
    loadComponent: () => import('./pages/single-ticket-page')
      .then(m => m.SingleTicketPageComponent)
  },
  {
    path: 'tickets/:type/:id/data',
    title: 'Add Ticket Clearing Data',
    loadComponent: () => import('./pages/add-ticket-data-page')
      .then(m => m.AddTicketDataPageComponent)
  },
  {
    path: 'create-ticket',
    title: 'Create a Issue',
    loadComponent: () => import('./pages/create-ticket-page')
      .then(m => m.CreateTicketPageComponent)
  },
  {
    path: 'settings/platform-partner',
    title: 'Platform Partner Configuration',
    loadComponent: () => import('./pages/platform-partner-config-page')
      .then(m => m.PlatformPartnerConfigPageComponent)
  },
  {
    path: 'settings/platform-partner/:id',
    title: 'Single Platform Partner',
    loadComponent: () => import('./pages/single-platform-partner-config-page')
      .then(m => m.SinglePlatformPartnerConfigPageComponent)
  },
  {
    path: 'settings/routing/rule/:id',
    title: 'Single Routing Rule',
    loadComponent: () => import('./pages/single-routing-rule')
      .then(m => m.SingleRoutingRuleComponent)
  },
  {
    path: 'errors',
    title: 'Error Tracking',
    loadComponent: () => import('./pages/error-tracking-page')
      .then(m => m.ErrorTrackingPageComponent)
  },
  {
    path: 'help',
    title: 'Help',
    loadComponent: () => import('./pages/help-page')
      .then(m => m.HelpPageComponent)
  },
  {
    path: '**',
    pathMatch: 'full',
    title: 'Page Not Found',
    loadComponent: () => import('@adlatus-gui/shared')
      .then(m => m.NotFoundPageComponent)
  }
].map(
  route => ({ ...route, canActivate: [authGuard] })
) as Route[];
