import {Component, Inject} from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MainLayoutComponent } from '@adlatus-gui/shared';
import { Ripple } from 'primeng/ripple';
import { RouterLink } from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import {ClearingTicketUtils} from "./core/util/listClearingTicket.util";
import {ituCodes} from "./core/constant/ituCodes";
import {ClearingTicketService, ITU_CODE} from "@adlatus-gui/domain/clearing";

@Component({
  standalone: true,
  imports: [MainLayoutComponent, Ripple, RouterLink, StyleClassModule],
  providers: [ClearingTicketUtils, ClearingTicketService],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'clearing-app';
  totalInbox = '0';
  totalOutbox = '0';

  private readonly _xTotalCount = 'X-Total-Count';

  constructor( @Inject(ITU_CODE) private ituCode: string,
               private listClearingTicketUtil:ClearingTicketUtils) {
    this.listClearingTicketUtil
      .inboxClearingTickets(ituCode)
      .subscribe(response=>{
        this.totalInbox = response.headers.get(this._xTotalCount)!;
      });
    this.listClearingTicketUtil
      .outboxClearingTickets(ituCode)
      .subscribe(response=>{
        this.totalOutbox = response.headers.get(this._xTotalCount)!;
      });
  }
}
