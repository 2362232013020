import { Component, Inject } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MainLayoutComponent } from '@adlatus-gui/shared';
import { Ripple } from 'primeng/ripple';
import { RouterLink } from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import { ClearingTicketUtils } from './core/util/clearingTicket.util';
import { ClearingTicketService } from '@adlatus-gui/domain/clearing';
import { ITU_CODE } from '@adlatus-gui/domain/clearing-custom';
import { environment } from '../environments/environment';

@Component({
  standalone: true,
  imports: [MainLayoutComponent, Ripple, RouterLink, StyleClassModule],
  providers: [ClearingTicketUtils, ClearingTicketService],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'clearing-app';
  totalInbox = '0';
  totalOutbox = '0';
  totalMyIssues = '0'
  partnershipMgmtUrl: string = environment.PARTNERSHIP_APP_URL;

  constructor(@Inject(ITU_CODE) private ituCode: string,
              private clearingTicketUtils: ClearingTicketUtils) {
    this.clearingTicketUtils
      .inboxClearingTickets(ituCode)
      .subscribe(response => {
        const count = response.headers.get(ClearingTicketUtils._xTotalCount)!;
        this.totalInbox = count;
        this.clearingTicketUtils.updateInboxCount(count);
      });

    this.clearingTicketUtils
      .outboxClearingTickets(ituCode)
      .subscribe(response => {
        const count = response.headers.get(ClearingTicketUtils._xTotalCount)!;
        this.totalOutbox = count;
        this.clearingTicketUtils.updateOutboxCount(count);
      });

    this.clearingTicketUtils
      .myTickets()
      .subscribe(response => {
        const count = response.length.toString();
        this.totalMyIssues = count;
        this.clearingTicketUtils.updateOutboxCount(count);
      });

    this.clearingTicketUtils.totalInbox$.subscribe(count => this.totalInbox = count);
    this.clearingTicketUtils.totalOutbox$.subscribe(count => this.totalOutbox = count);
    this.clearingTicketUtils.totalMyIssues$.subscribe(count => this.totalMyIssues = count);

  }
}
