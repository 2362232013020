<lib-main-layout>
  <ng-template #navigationTemplate>
    <ul class='list-none p-3 m-0'>
      <li>
        <div pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown' leaveToClass='hidden'
             leaveActiveClass='slideup'
             class='p-ripple p-element p-3 flex align-items-center justify-content-between text-600 cursor-pointer'>
          <span class='font-medium'>MAIN</span><i class='pi pi-chevron-down'></i><span class='p-ink'></span>
        </div>
        <ul class='list-none p-0 m-0 overflow-hidden'>
          <li>
            <a pRipple routerLink=''
               class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
              class='pi pi-home mr-2'></i><span class='font-medium'>Dashboard</span><span class='p-ink'></span></a>
          </li>


          <li><a pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown'
                 leaveToClass='hidden' leaveActiveClass='slideup'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-ticket mr-2'></i><span class='font-medium'>Issues</span><i
            class='pi pi-chevron-down ml-auto'></i><span class='p-ink'
                                                         style='height: 247px; width: 247px; top: -94.5px; left: 5.5px;'></span></a>
            <ul
              class='list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out hidden'>
              <li><a pRipple routerLink='tickets/inbox'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-inbox mr-2'></i><span class='font-medium'>Inbox</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalInbox }}</span><span class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='tickets/outbox'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-send mr-2'></i><span class='font-medium'>Outbox</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalOutbox }}</span><span class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='tickets/myIssues'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-list-check mr-2'></i><span class='font-medium'>My Issues</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalMyIssues }}</span><span class='p-ink'></span></a>
              </li>
              <li><a pRipple
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-eye mr-2'></i><span class='font-medium'>Watching Issues</span><span
                class='p-ink'></span></a></li>
            </ul>
          </li>


          <li><a pRipple routerLink='create-ticket'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-plus-circle mr-2'></i><span class='font-medium'>Create an Issue</span><span
            class='p-ink'></span></a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class='list-none p-3 m-0'>
      <li>
        <div pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown' leaveToClass='hidden'
             leaveActiveClass='slideup'
             class='p-ripple p-element p-3 flex align-items-center justify-content-between text-600 cursor-pointer'>
          <span class='font-medium'>APPLICATION</span><i class='pi pi-chevron-down'></i><span class='p-ink'></span>
        </div>
        <ul class='list-none p-0 m-0 overflow-hidden'>

          <li><a pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown'
                 leaveToClass='hidden' leaveActiveClass='slideup'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-cog mr-2'></i><span class='font-medium'>Settings</span><i
            class='pi pi-chevron-down ml-auto'></i><span class='p-ink'
                                                         style='height: 247px; width: 247px; top: -94.5px; left: 5.5px;'></span></a>
            <ul
              class='list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out hidden'>
              <li><a pRipple [href]='partnershipMgmtUrl' target='_blank'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-users mr-2'></i><span class='font-medium'>Partnership Mgmt.</span><span
                class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='settings/routing'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-directions mr-2'></i><span class='font-medium'>Routing Config</span><span
                class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='settings/sla'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-sliders-v mr-2'></i><span class='font-medium'>SLA Config</span><span
                class='p-ink'></span></a>
              </li>
            </ul>
          </li>

          <li><a pRipple routerLink='errors'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-exclamation-circle mr-2'></i><span class='font-medium'>Error Tracking</span><span
            class='inline-flex align-items-center justify-content-center ml-auto bg-red-400 text-0 border-circle'
            style='min-width: 1.5rem; height: 1.5rem;'>1</span><span class='p-ink'></span></a>
          </li>

          <li><a pRipple routerLink='help'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-question-circle mr-2'></i><span class='font-medium'>Help</span><span class='p-ink'></span></a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-template>
</lib-main-layout>
