/**
 * Partnership Management
 * This is Swagger UI environment generated for the TMF Partnership Management specification
 *
 * OpenAPI spec version: 4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Partnership } from '../model/partnership';
import { PartnershipCreate } from '../model/partnershipCreate';
import { PartnershipUpdate } from '../model/partnershipUpdate';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class PartnershipService {

  protected basePath = 'https://serverRoot/tmf-api/partnershipManagement/v4/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Creates a Partnership
   * This operation creates a Partnership entity.
   * @param body The Partnership to be created
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPartnership(body: PartnershipCreate, observe?: 'body', reportProgress?: boolean): Observable<Partnership>;
  public createPartnership(body: PartnershipCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Partnership>>;
  public createPartnership(body: PartnershipCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Partnership>>;
  public createPartnership(body: PartnershipCreate, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createPartnership.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Partnership>('post', `${this.basePath}/partnership`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes a Partnership
   * This operation deletes a Partnership entity.
   * @param id Identifier of the Partnership
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePartnership(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deletePartnership(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deletePartnership(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deletePartnership(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deletePartnership.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/partnership/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * List or find Partnership objects
   * This operation list or find Partnership entities
   * @param fields Comma-separated properties to be provided in response
   * @param offset Requested index for start of resources to be provided in response
   * @param limit Requested number of resources to be provided in response
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listPartnership(fields?: string, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Partnership>>;
  public listPartnership(fields?: string, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Partnership>>>;
  public listPartnership(fields?: string, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Partnership>>>;
  public listPartnership(fields?: string, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Partnership>>('get', `${this.basePath}/partnership`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Updates partially a Partnership
   * This operation updates partially a Partnership entity.
   * @param body The Partnership to be updated
   * @param id Identifier of the Partnership
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchPartnership(body: PartnershipUpdate, id: string, observe?: 'body', reportProgress?: boolean): Observable<Partnership>;
  public patchPartnership(body: PartnershipUpdate, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Partnership>>;
  public patchPartnership(body: PartnershipUpdate, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Partnership>>;
  public patchPartnership(body: PartnershipUpdate, id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchPartnership.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchPartnership.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Partnership>('patch', `${this.basePath}/partnership/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves a Partnership by ID
   * This operation retrieves a Partnership entity. ClearingDataAttribute selection is enabled for all first level attributes.
   * @param id Identifier of the Partnership
   * @param fields Comma-separated properties to provide in response
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retrievePartnership(id: string, fields?: string, observe?: 'body', reportProgress?: boolean): Observable<Partnership>;
  public retrievePartnership(id: string, fields?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Partnership>>;
  public retrievePartnership(id: string, fields?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Partnership>>;
  public retrievePartnership(id: string, fields?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling retrievePartnership.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (fields !== undefined && fields !== null) {
      queryParameters = queryParameters.set('fields', <any>fields);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Partnership>('get', `${this.basePath}/partnership/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
