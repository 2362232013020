import { MessageService } from 'primeng/api';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiErrorInterceptor } from '@adlatus-gui/shared';
import { BASE_PATH as PARTNERSHIP_API_URI } from '@adlatus-gui/domain/partnership';
import { BASE_PATH as CLEARING_API_URI, ITU_CODE } from '@adlatus-gui/domain/clearing';
import { BASE_PATH as CLEARING_CUSTOM_API_URI } from '@adlatus-gui/domain/clearing-custom';
import { authInterceptor, provideAuthentication } from '@adlatus-gui/domain/authentication';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideHttpClient(withInterceptors([
      authInterceptor,
      apiErrorInterceptor
    ])),
    provideRouter(appRoutes),
    MessageService,
    ...provideAuthentication(environment.KEYCLOAK),
    { provide: ITU_CODE, useValue: environment.ITU_CODE },
    { provide: CLEARING_API_URI, useValue: environment.CLEARING_API_ENDPOINT },
    { provide: CLEARING_CUSTOM_API_URI, useValue: environment.CLEARING_CUSTOM_API_ENDPOINT },
    {
      provide: PARTNERSHIP_API_URI,
      useValue: environment.PARTNERSHIP_API_ENDPOINT
    }
  ]
};
